@import "@/assets/scss/style.scss";

.c-cms-show {
    padding: 24px;
    width: 50%;
    min-width: 760px;
    margin: 16px auto;
    border-radius: 10px;
    background-color: $white-two;
    align-items: center;
}

@media (max-width: 768px) {
    .c-cms-show {
        width: 100%;
        min-width: 100%;
    }
}
