@import "@/assets/scss/style.scss";

.c-cms-show {
    &__button-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    &__button {
        margin-bottom: 24px;

        @media (max-width: 481px) {
            min-width: auto;
            font-size: 14px;
            padding: 0.7rem;
        }
    }

    &__dropdown {
        width: 400px;
        margin: 0 auto 16px auto;
        display: block;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
}
