@import "@/assets/scss/style.scss";

.c-cms-show-list {
    &__single-list {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        text-transform: capitalize;
    }

    &__search {
        margin-bottom: 16px;
    }

    &__image-wrapper {
        margin-right: 16px;

        img {
            border-radius: 3px;
            width: 50px;
        }
    }
}

.ant-list-item-meta {
    align-items: center;
}
